import * as React from "react";
import { NavigateNext } from "@mui/icons-material";
import { Box, Button, Dialog, Typography } from "@mui/material";
import image from "svg_icons/financial-analytics-tour/old-project-slide.svg";

import { BodyText } from "./BasicTour.styles";

interface IProps {
  onComplete(): void;
}

const DIALOG_HEIGHT = 391;
const DIALOG_WIDTH = 690;
const GUIDE_URL = "https://fondhelp.biarrinetworks.com/how-to-get-your-old-project-running-with-reporting";

const ReportOldProjectTour: React.FC<IProps> = ({ onComplete }) => (
  <Dialog open maxWidth={false}>
    <Box data-testid="report-old-project-tour" display="flex" flexDirection="column" height={DIALOG_HEIGHT} width={DIALOG_WIDTH} py={2} px={2.5}>
      <Box flex={1} display="flex" columnGap={5} py={6} px={4}>
        <Box flex={1} sx={{ background: `url(${image}) center no-repeat`, backgroundSize: "contain" }} />
        <Box flex={1}>
          <Typography variant="h5" component="h3" fontWeight={700} gutterBottom>
            How to Get Your "Old Project" Running with Reporting
          </Typography>
          <br />
          <BodyText component="p">
            Experiencing the 'Outdated project' warning when selecting a project for your financial report? Check our&nbsp;
            <a href={GUIDE_URL} target="_blank" rel="noreferrer">
              knowledge base
            </a>
            &nbsp;for guidance on resolving this issue.
          </BodyText>
          <br />
          <Button variant="contained" size="small" endIcon={<NavigateNext />} component="a" href={GUIDE_URL} target="_blank">
            Learn more
          </Button>
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-end" height={42}>
        <Button onClick={onComplete} size="small">
          Close
        </Button>
      </Box>
    </Box>
  </Dialog>
);

export default ReportOldProjectTour;
